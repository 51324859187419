// import { configureStore } from '@reduxjs/toolkit'
// import userSlice from './userSlice'

//  const store = configureStore({
//   reducer: {
//     user: userSlice,
//   },
// })

// export default store


import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root-model",
  version: 1,
  storage,
};

const rootReducer = combineReducers({ user: userReducer});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);


/*

export default configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
    },
});




*/