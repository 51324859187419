import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {  saveUser } from '../store/apiCalls';
import { useDispatch } from "react-redux";
import { Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router';

export default function AddUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    console.log({
        email: data.get('email'),
        username: data.get('username'),
        post: data.get('post'),
        isAdmin: data.get('admin')?true:false,
        password: data.get('password'),
      });
      saveUser(dispatch,{
      email: data.get('email'),
      username: data.get('username'),
      post: data.get('post'),
      isAdmin: data.get('admin')?true:false,
      password: data.get('password'),
    },navigate)
  };
  

  return (
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <h1 className='title' >Add New User</h1>
          <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete= "no"
              inputProps={{
                autoComplete: ''
             }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete= "no"

              inputProps={{
                autoComplete: ''
             }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="post"
              label="Post"
              name="post"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
            />
            <FormControlLabel control={<Checkbox name='admin' />} label="Admin" style={{color:"white"}} />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className="blue"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
  );
}
