import { Autocomplete, Box, Button, Container, Modal, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { userRequest } from '../requestMethods';
import { editLigne, saveLigne, saveTask } from '../store/apiCalls';
import { useDispatch } from 'react-redux';
import { setLoading, setNotification } from '../store/userSlice';
import { useNavigate, useParams } from 'react-router';
function EditLigne() {
    function removeT() {
        let t = [...Tc];
        t.pop()
        setTc(t)
    }
    let { id } = useParams();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [Tc, setTc] = useState([0])
    const [ligne, setLigne] = useState({})
    const [newTask, setnewTask] = useState("")
    const [tasks, setTasks] = useState([])
    const [types, setTypes] = useState([])
    const [ref, setRef] = useState("")
    const [lignes, setLignes] = useState([])
    const [value, setValue] = useState("")


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (Tc.includes(0)) {
            dispatch(setNotification({
                msg: "please check your Inputs",
                error: true
            }))
        } else {
            console.log({
                name: data.get('name'),
                ref: types.find(t => t.name === ref)._id,
                position: data.get('position'),
                ligne: lignes.find(l => l.name === value)._id,
                tasks: Tc
            });
            editLigne(dispatch, {
                name: data.get('name'),
                ligne: lignes.find(l => l.name === value)._id,
                ref: types.find(t => t.name === ref)._id,
                position: data.get('position'),
                tasks: Tc
            }, navigate, id)
        }
    }

    const getData = async (uri) => {
        dispatch(setLoading(true));
        try {

            const { data } = await userRequest.get(uri);
            setTasks(data.tasks);
            setTc(data.machine.tasks.map(t => t._id))
            setLigne(data.machine);
            setLignes(data.lignes);
            setValue(data.machine.ligne.name)
            setTypes(data.types);
            setRef(data.types.find(t => t._id === data.machine.ref).name || null)
            console.log(ref);
            dispatch(setLoading(false));

        } catch (e) {
            dispatch(setLoading(false));
        }
    };
    React.useEffect(() => {
        getData(`/machine/edit/${id}`);
    }, []);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "60%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        color: "white",
        p: 4,

    };

    function saveNewTask() {
        let a = saveTask(dispatch, { name: newTask })
        a.then(d => {
            setTasks([...tasks, d])
            setOpen(false)
        }).catch(e => console.log(e))
    }

    function setTaskId(i, value) {
        let t = [...Tc]
        let s = tasks.find(t => t.name === value)
        t[i] = s ? s._id : 0
        setTc(t)
    }

    return (

        ligne.name && (
            <Container component="main" maxWidth="md">
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add New Task
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="task"
                                label="Task"
                                type="text"

                                onChange={e => setnewTask(e.target.value)}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                className="blue"
                                onClick={saveNewTask}
                            >
                                Save
                            </Button>
                        </Typography>
                    </Box>
                </Modal>
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                    }}
                >
                    <h1 className='title' >Edit Machine</h1>

                    <Box component="form" className='flexForm' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <div>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Name"
                                name="name"
                                autoFocus
                                defaultValue={ligne.name}
                            />
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                defaultValue={value}
                                options={lignes.map(d => d.name)}
                                sx={{ maxWidth: 400, width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select Ligne" />}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="position"
                                label="Position"
                                type="text"
                                defaultValue={ligne.position}
                            />
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    // setValue(newValue);
                                    console.log(types.find(t => t.name === newValue));
                                    setTc(types.find(t => t.name === newValue).tasks)
                                    setRef(newValue)
                                }}
                                options={types.map(d => d.name)}
                                defaultValue={ref}
                                sx={{ maxWidth: 400, width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select Type" />}
                            />




                        </div>
                        <div style={{ paddingTop: 15 }}>
                            <Stack direction="row" spacing={2} style={{ display: "flex", justifyContent: "flex-start", marginBottom: 20 }} >
                                <Button variant="contained" endIcon={<PlaylistAddIcon />} onClick={handleOpen} >
                                    New
                                </Button>
                                <Button variant="contained" endIcon={<AddBoxIcon />} onClick={e => setTc([...Tc, 0])} >
                                    Add
                                </Button>
                                <Button variant="contained" startIcon={<DeleteIcon />} onClick={e => removeT()} >
                                    Delete
                                </Button>
                            </Stack>
                            {
                                Tc.map((t, i) => (
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            //setValue(newValue);
                                            setTaskId(i, newValue)
                                        }}
                                        id="controllable-states-demo"
                                        key={i}
                                        options={tasks.map(d => d.name)}
                                        defaultValue={tasks.find(ti => ti._id === t)?.name || null}
                                        sx={{ width: "100%", mb: 1 }}
                                        renderInput={(params) => <TextField {...params} label={`Task ${i + 1}`} />}
                                    />

                                ))
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                className="blue"
                            >
                                Save
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Container>

        )

    )
}

export default EditLigne