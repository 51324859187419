import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { useDispatch, useSelector } from 'react-redux';
import { loginOut } from '../store/userSlice';
import { NavLink, useNavigate } from 'react-router-dom';

const pages = ['Lignes','Machines', 'Users', 'TasksCheck',"Tasks","Dashboard","Types","Downloads"];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const handleOpenNavMenu = (event) => {
    if(!user.currentUser.isAdmin )
    return;
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigate = useNavigate();

  return (
    <AppBar position="sticky" color="primary">
      <Container maxWidth="xl">
          <div className='header_title' onClick={(e) => navigate(`/`)} >Startup CheckList</div>
        <Toolbar disableGutters className='tool'>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {user.currentUser.isAdmin && pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu} color="inherit">
                  <NavLink to={page} className="navlnk" style={({ isActive, isPending }) => {
                    return {
                      color: isActive ? "#0092a8" : "inherit",
                    };
                  }}
                  >
                    <Typography textAlign="center">{page}</Typography>

                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <NavLink to={"/"} className="navlnk" style={({ isActive, isPending }) => {
                    return {
                      color: isActive ? "#0092a8" : "inherit",
                    };
                  }}
                  > */}
                    <div className='logos' >

          <img src='detj.png' className='logo2 ll1' sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' } }} />
                    </div>

                  {/* </NavLink> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {user.currentUser.isAdmin && pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                 <NavLink to={page} className="navlnk" style={({ isActive, isPending }) => {
                    return {
                      color: isActive ? "#0092a8" : "inherit",
                    };
                  }}
                  >
                    <Typography textAlign="center">{page}</Typography>

                  </NavLink>
              </Button>
            ))}
          </Box>
          <div className='logos' style={{paddingBottom: 12}} >

<img src='dr.webp' className='logo2' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
<img src="logo_c2i.png" style={{paddingBottom:0}} className='logo2' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
          </div>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <div className='logo' style={{    marginBottom: 12}}>
                  <span >{user.currentUser.username[0]}</span>

                </div>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={e => dispatch(loginOut())}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              <MenuItem onClick={e => window.close()}>
                <Typography textAlign="center">Exit App</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
