import React from 'react'

function Date({d}) {
function formatDate() {
    let dd = d
    dd = dd.replace("T"," , ")
    dd =dd.substring(0,dd.indexOf("."))
    return dd
    
}
  return (
    <>{formatDate()}</>
  )
}

export default Date