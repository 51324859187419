import React from 'react'
import { useNavigate } from 'react-router';

function Show2({data}) {
  const navigate = useNavigate();
    // console.log(data);
    function nav(mm) {
      if(mm.inPost)
      navigate(`/taskschecker/edit/${mm.id}`)
    }
  return (
    
        data && (
          <div className={"pa"+data.length}>

            {
              data.map((m,i) => (
                <div onClick={e => nav(m)} className={"tt"+(i+1)} style={{backgroundColor:!m.inPost ? "#F9690E	" : m.tasks?"#367E18":"#CC3636"}} >{m.name}</div>
              ))
            }
            {/* <div className='tt2' style={{backgroundColor:data[0].tasks?"#367E18":"#CC3636"}} >USS1</div>
            {/* {/* <div className='tt3' style={{backgroundColor:data[0].tasks?"#367E18":"#CC3636"}} >USS1</div> */}
            {/* <div className='tt4' style={{backgroundColor:data[0].tasks?"#367E18":"#CC3636"}} >USS1</div>  */}
          </div>
        )
    
  )
}

export default Show2