import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { Box, Button } from '@mui/material';
import { userRequest } from '../requestMethods';

import EditIcon from '@mui/icons-material/Edit';
import SearchAddBar from '../components/SearchAddBar';
import { useNavigate } from 'react-router';
import { setLoading } from '../store/userSlice';
import { useDispatch } from 'react-redux';

export default function Lignes() {
    const [machine, setMachine] = React.useState([])
    const [search, setsearch] = React.useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getData = async (uri) => {
        dispatch(setLoading(true));
        try{
           
            const { data } = await userRequest.get(uri);
            setMachine(data);
            dispatch(setLoading(false));

        }catch(e){
            dispatch(setLoading(false));
        }
    };
    function searchH(text) {
        setsearch(text)
    }

    React.useEffect(() => {
        if(search.length >= 3){
            getData(`/machine/search/${search}`);
        }else if(search.length === 0){
            getData(`/machine/last`);
        }
    }, [search]);
    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h1 className='title' >Machines</h1>
                <SearchAddBar searchHandler={searchH} uri="/machines/add"/>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Ligne</TableCell>
                                <TableCell align="center">QR Code</TableCell>
                                <TableCell align="center">Postion</TableCell>
                                <TableCell align="center">Ref</TableCell>
                                <TableCell align="center">Number Of Tasks</TableCell>
                                <TableCell align="center">Created At</TableCell>
                                <TableCell align="center">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {machine.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.ligne?.name}</TableCell>
                                    <TableCell align="center">{row.ligne?._id}</TableCell>
                                    <TableCell align="center">{row.position}</TableCell>
                                    <TableCell align="center">{row.ref?.name}</TableCell>
                                    <TableCell align="center">{row.tasks.length}</TableCell>
                                    <TableCell align="center">{row.createdAt}</TableCell>
                                    <TableCell align="center"><Button style={{backgroundColor: "orange"}} onClick={e => navigate(`/machines/edit/${row._id}`)} variant="contained" endIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
}
