import axios from "axios";

const BASE_URL = "http://localhost:4000/api/v1/";

// const BASE_URL = "http://cheklist.detj.c2i.tn/api/v1/";

const user = JSON.parse(localStorage.getItem("persist:root-model"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken;



export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});



// userRequest.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   console.log(error.response.data);

//   return Promise.reject(error);
// });