import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const NotificationAndLoading = () => {
    const [open, setOpen] = React.useState(false);
    const [openL, setOpenL] = React.useState(false);

  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const user = useSelector((state) => state.user);
    const [first, setfirst] = useState(true)
    useEffect(() => {
      if(first){
        setfirst(false)
        return;
      }
        setOpen(true);
      }, [user.notification]);

    useEffect(() => {
        setOpenL(user.loading);
      }, [user.loading]);

  return (
    <div>
    <Snackbar className='noti' anchorOrigin={ {vertical: 'top',horizontal: 'right' }} open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={user.notification.error?"error":"success"} sx={{ width: '100%' }}>
            {user.notification.msg}
        </Alert>
    </Snackbar>


    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openL}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default NotificationAndLoading