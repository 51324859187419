import { Button, IconButton, InputBase, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';

import AddCircleIcon from '@mui/icons-material/AddCircle';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
export default function SearchAddBar({ searchHandler, uri, clickHandler }) {
  const navigate = useNavigate();

  return (
    <div className='subNav' style={{ width: "100%", padding: 0 }} >
      <Toolbar className='tool'>
        <Search>
          <SearchIconWrapper>
            <SearchIcon className='svg' />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={e => searchHandler(e.target.value)}
          />
        </Search>
        <div>
          {
            uri ? (
              <Button variant="contained" endIcon={<AddCircleIcon />} onClick={e => navigate(uri)}>
                Add New
              </Button>

            ) :clickHandler ? (

              <Button variant="contained" endIcon={<AddCircleIcon />} onClick={clickHandler}>
                Add New
              </Button>
            ):("")
          }

        </div>
      </Toolbar>
    </div>
  );
}
