import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { Box, Button } from '@mui/material';
import { userRequest } from '../requestMethods';

import EditIcon from '@mui/icons-material/Edit';
import SearchAddBar from '../components/SearchAddBar';
import { useNavigate } from 'react-router';
import { setLoading, setNotification } from '../store/userSlice';
import { useDispatch } from 'react-redux';




export default function Types() {
    const [users, setUsers] = React.useState([])
    const [search, setsearch] = React.useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const getData = async (uri) => {
        dispatch(setLoading(true));
        try{
            const { data } = await userRequest.get(uri);
            setUsers(data);
            dispatch(setLoading(false));

        }catch(e){
            dispatch(setLoading(false));
        }
    };
    function searchH(text) {
        setsearch(text)
    }

    React.useEffect(() => {
        if(search.length >= 3){
            getData(`/types/search/${search}`);
        }else if(search.length === 0){
            getData(`/types?new`);
        }
    }, [search]);
    return (
        <Container component="main" maxWidth="md" id="InvoiceTemp" >
            <Box
                sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h1 className='title' >Types</h1>
                {/* <Button style={{backgroundColor: "orange"}} onClick={e => pdf()} variant="contained" endIcon={<EditIcon />}>
                                        pdf
                                    </Button>
                <Button style={{backgroundColor: "blue"}} onClick={e => pdf2()} variant="contained" endIcon={<EditIcon />}>
                                        pdf2
                                    </Button> */}
                <SearchAddBar searchHandler={searchH} uri="/types/add"/>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Type Name</TableCell>
                                <TableCell align="center">Created At</TableCell>
                                <TableCell align="center">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((row) => (
                                <TableRow
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.createdAt}</TableCell>
                                    <TableCell align="center"><Button style={{backgroundColor: "orange"}} onClick={e => navigate(`/types/edit/${row._id}`)} variant="contained" endIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
}
