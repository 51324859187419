import React from 'react'
import { useLocation } from 'react-router';

const Footer = () => {
  const location = useLocation();
  return (
    <>
    <div className={location.pathname != "/NotFound" ?"footer":""}>
        </div>
    <footer >© 2022 &nbsp; <a href='http://www.c2i.tn/' className='f-link'>C2I</a>&nbsp;  All rights reserved.</footer>
    
    </>
  )
}

export default Footer