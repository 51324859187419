import { loginFailure, loginStart, loginSuccess, setNotification } from "./userSlice";
import { publicRequest, userRequest } from "../requestMethods";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
    document.  location.reload();
    } catch (err) {
    dispatch(loginFailure());
  }
};
export const saveTask = async (dispatch, task) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.post("/task", task);
    dispatch(setNotification({
      msg: "Task Saved",
      error: false
    }));
    return res.data
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
    return null
  }
};
export const editTask = async (dispatch, task,id) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.put(`/task/${id}`, task);
    dispatch(setNotification({
      msg: "Task Saved",
      error: false
    }));
    return res.data
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
    return null
  }
};
export const saveLigne = async (dispatch, ligne,nav) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.post("/machine", ligne);
    dispatch(setNotification({
      msg: "Ligne Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/machines")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};
export const saveType = async (dispatch, type,nav) => {
  // console.log(type);
  dispatch(loginStart());
  try {
    const res = await userRequest.post("/types", type);
    dispatch(setNotification({
      msg: "Type Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/types")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};




export const saveUser = async (dispatch, user,nav) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.post("/user", user);
    dispatch(setNotification({
      msg: "User Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/users")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};
export const editUser = async (dispatch, user,nav,id) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.put(`/user/${id}`, user);
    dispatch(setNotification({
      msg: "User Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/users")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};
export const editLigne = async (dispatch, ligne,nav,id) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.put(`/machine/${id}`, ligne);
    dispatch(setNotification({
      msg: "Machine Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/machines")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};
export const editType = async (dispatch, type,nav,id) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.put(`/types/${id}`, type);
    dispatch(setNotification({
      msg: "Type Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/Types")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};

export const saveChecker = async (dispatch, data, nav) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.post("/machineTasksChecker", data);
    dispatch(setNotification({
      msg: "Checker Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};
export const editChecker = async (dispatch, data, nav,id) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.put(`/machineTasksChecker/${id}`, data);
    dispatch(setNotification({
      msg: "Checker Saved",
      error: false
    }));
    setTimeout(() => {
      nav("/TasksCheck")
    }, 2000)
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
  }
};
export const deleteChecker = async (dispatch,id) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.delete(`/machineTasksChecker/${id}`);
    dispatch(setNotification({
      msg: "Checker Deleted",
      error: false
    }));
    return true
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
    return false
  }
};



export const saveChain = async (dispatch, task) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.post("/ligne", task);
    dispatch(setNotification({
      msg: "Task Saved",
      error: false
    }));
    return res.data
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
    return null
  }
};


export const editChain = async (dispatch, task,id) => {
  dispatch(loginStart());
  try {
    const res = await userRequest.put(`/ligne/${id}`, task);
    dispatch(setNotification({
      msg: "Task Saved",
      error: false
    }));
    return res.data
  } catch (err) {
    dispatch(setNotification({
      msg: "Failed Try agin",
      error: true
    }));
    return null
  }
};