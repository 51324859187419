import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { userRequest } from '../requestMethods';

import EditIcon from '@mui/icons-material/Edit';
import SearchAddBar from '../components/SearchAddBar';
import { editChain, saveChain } from '../store/apiCalls';
import { useDispatch } from 'react-redux';
import { setLoading } from '../store/userSlice';

export default function Chaines() {
    const [taskss, setTaskss] = React.useState([])
    const [search, setsearch] = React.useState("")

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [newTask, setnewTask] = React.useState("")
    const [taskId, setTaskId] = React.useState("")
    const dispatch = useDispatch();


    const getData = async (uri) => {
        dispatch(setLoading(true));
        try{
            const { data } = await userRequest.get(uri);
        setTaskss(data);
            dispatch(setLoading(false));

        }catch(e){
            dispatch(setLoading(false));
        }
        
    };
    function searchH(text) {
        setsearch(text)
    }

    React.useEffect(() => {
        if(search.length >= 3){
            getData(`/ligne/search/${search}`);
        }else if(search.length === 0){
            getData(`/ligne/last`);
        }
    }, [search]);
    function saveNewTask() {
        if(taskId === ""){
            let a = saveChain(dispatch, { name: newTask })
            a.then(d => {
                setTaskss([d,...taskss])
                setOpen(false)
            }).catch(e => console.log(e))

        }else{
            let a = editChain(dispatch, { name: newTask },taskId)
            a.then(d => {
                let t = [...taskss]
                t.find(z => z._id === taskId).name = d.name
                setTaskss(t)
                setOpen(false)
                setTaskId("")
            }).catch(e => console.log(e))
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "60%",
        bgcolor: '#2e2e2e',
        border: '2px solid #000',
        boxShadow: 24,
        color: "white",
        p: 4,

    };
    function editHandler(id) {
        setTaskId(id)
        setnewTask(taskss.find(t => t._id === id).name)
        setOpen(true)
    }
    function newHandler() {
        setTaskId("")
        setnewTask("")
        setOpen(true)
    }
    return (
        <Container component="main" maxWidth="md">
             <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add New Task
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="task"
                            label="Task"
                            type="text"
                            onChange={e => setnewTask(e.target.value)}
                            value={newTask}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            className="blue"
                            onClick={saveNewTask}
                        >
                            Save
                        </Button>
                    </Typography>
                </Box>
            </Modal>
            <Box
                sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h1 className='title' >Lignes</h1>
                <SearchAddBar searchHandler={searchH} clickHandler={(e) => newHandler()}/>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Created At</TableCell>
                                <TableCell align="center">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {taskss.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.createdAt}</TableCell>
                                    <TableCell align="center"><Button style={{backgroundColor: "orange"}} onClick={e => editHandler(row._id)} variant="contained" endIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
}
