import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { userRequest } from '../requestMethods';

import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import { deleteChecker } from '../store/apiCalls';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { setLoading } from '../store/userSlice';
import SearchAddBar from '../components/SearchAddBar';

export default function TasksCheck() {
    const [tasksC, setTasksC] = React.useState([])
    const [value, setValue] = React.useState("");
    const [machine, setMachines] = React.useState([]);
    const [lignes, setLignes] = React.useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getData = async (uri) => {
        dispatch(setLoading(true));
        try{
            const { data } = await userRequest.get(uri);
            setTasksC(data.machineTasks);
            if(data.machines)
            setMachines(data.machines);
            if(data.lignes)
            setLignes(data.lignes);
            dispatch(setLoading(false));

        }catch(e){
            dispatch(setLoading(false));
        }
       
    };

    React.useEffect(() => {
        getData(`/machineTasksChecker`);
    }, []);
    React.useEffect(() => {
        if(value)
        getData(`/machineTasksChecker/machine/${machine.find(m => m.name === value)._id}`);
        else
        getData(`/machineTasksChecker`);
    }, [value]);

    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState("");

    const handleClickOpen = (id) => {
        setOpen(true);
        setId(id)

    };
    const deleteAgree = () => {
        deleteChecker(dispatch, id).then(d => {
            setTasksC(tasksC.filter(t => t._id != id))
            setId("")
        }).catch(e => console.log(e));
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setId("")
    };


    return (
        <Container component="main" maxWidth="md">
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure to remove this checklist?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    This checklist will be permanently deleted and will not be refunded
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={deleteAgree} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h1 className='title' >TasKs Checker</h1>
                <div className='tool2'>
                <Autocomplete
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    id="controllable-states-demo"
                    options={machine.map(d => d.name)}
                    sx={{ maxWidth: 400,width:"80%" }}
                    renderInput={(params) => <TextField {...params} label="Filter By Machines Name" />}
                />
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">User Name</TableCell>
                                <TableCell>Machine Name</TableCell>
                                <TableCell>Ligne Name</TableCell>
                                <TableCell align="center">Tasks</TableCell>
                                <TableCell align="center">Created At</TableCell>
                                <TableCell align="center">Edit</TableCell>
                                <TableCell align="center">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasksC[0] && tasksC.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.user?.username}
                                    </TableCell>
                                    <TableCell align="center">{ row.machine.name}</TableCell>
                                    <TableCell align="center">{ lignes.find(l => l._id ===  row.machine.ligne).name}</TableCell>
                                    <TableCell align="center">{`${row.tasks.filter(t => t.status).length}/${row.tasks.length}`}</TableCell>
                                    <TableCell align="center">{row.createdAt}</TableCell>
                                    <TableCell align="center"><Button style={{ backgroundColor: "orange" }} onClick={e => navigate(`/taskschecker/edit/${row._id}`)} variant="contained" endIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                    </TableCell>
                                    <TableCell align="center"><Button style={{ backgroundColor: "red" }} onClick={e => handleClickOpen(row._id)} variant="contained" endIcon={<DeleteIcon />}>
                                        Delete
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
}
