import * as React from 'react';
import { userRequest } from '../requestMethods';


import { useDispatch } from 'react-redux';
import { setLoading } from '../store/userSlice';

import LigneCom from '../components/LigneCom';

export default function Dashboard() {
    const [tasks, setTasks] = React.useState(null)
    const [types, setTypes] = React.useState({})
    const textInput = React.useRef(null);
    const [machaines, setMachaines] = React.useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ])
    const dispatch = useDispatch();
    let nowDate = new Date();

    function checkPost (date){
        let d = new Date(date);
        if(d >nowDate){
            return true
        }
        return false

    }
    
    function setNowDate(){
        nowDate = new Date();   
        // 6 14 22
        // nowDate = new Date("2023-01-25T11:37:24.947Z");
        if(nowDate.getUTCHours()+1 >= 22){
            let cvb =`${nowDate.getFullYear()}-${nowDate.getMonth()+1}-${nowDate.getDate()}T22:00:00.947Z`;
            nowDate = new Date(cvb);   
        }else if(nowDate.getUTCHours()+1 >= 14){
            let cvb =`${nowDate.getFullYear()}-0${nowDate.getMonth()+1}-${nowDate.getDate()}T14:00:00.947Z`;
            nowDate = new Date(nowDate.getFullYear(),nowDate.getMonth(),nowDate.getDate(),14,0,0);   
            console.log(cvb);
        }else if(nowDate.getUTCHours()+1 >= 6){
            let cvb =`${nowDate.getFullYear()}-0${nowDate.getMonth()}-${nowDate.getDate()}T06:00:00.947Z`;
            console.log(cvb);
            nowDate = new Date(nowDate.getFullYear(),nowDate.getMonth(),nowDate.getDate(),6,0,0);   
        }else{
            var yy = new Date(Date.now() - 86400000);
            let cvb =`${yy.getFullYear()}-${yy.getMonth()+1}-${yy.getDate()}T22:00:00.947Z`;
            nowDate = new Date(cvb);  
        }
    }
    const getData = async (uri) => {
        setTasks(null)
        dispatch(setLoading(true));
        try {
            const { data } = await userRequest.get(uri);
            console.log(data);
            setTypes(data.types);
            setNowDate()
 
            var c = data.machineTasks.map(d => {
                return {
                    ligne: d.machine.ligne.name,
                    name: d.machine.name,
                    position: d.machine.position,
                    user: d.user.username,
                    tasks: d.tasks.map(t => t.status).every(Boolean),
                    ref:d.machine.ref,
                    id:d._id,
                    createdAt:d.createdAt,
                    inPost:checkPost(d.createdAt)
                }
            })
            Array.prototype.groupBy = function (key) {
                return this
                    .reduce((hash, obj) => {
                        if (obj[key] === undefined) return hash;
                        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
                    }, {})
            };
            c = c.groupBy("ligne")
            console.log(c);
           
            setTasks(c);
            dispatch(setLoading(false));

        } catch (e) {
            dispatch(setLoading(false));
        }

    };
    React.useEffect(() => {
        getData(`/machineTasksChecker/status`);
    }, []);


    // React.useEffect(() => {
        
    //     let interval = setInterval(() => getData(`/machineTasksChecker/status`), 60000);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);

    return tasks && (
        <div className='con'>
            {
                Object.keys(tasks).map(t => (
                    <LigneCom data={tasks[t]} types={types} key={t} />
                ))
            }
            {/* <LigneCom data={tasks.IR23} /> */}
            {/* <LigneCom data={tasks.IR25} types={types}/> */}
            
        </div>
    );
}
