import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";

import { Provider } from 'react-redux'
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import axios from 'axios';
import { loginOut } from './store/userSlice';
import { userRequest } from './requestMethods';


const root = ReactDOM.createRoot(document.getElementById('root'));
const {dispatch} = store; // direct access to redux store.

window.safta= 34;
const UNAUTHORIZED = 401;
userRequest.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === UNAUTHORIZED) {
      dispatch(loginOut());
    }
   return Promise.reject(error);
 }
);
root.render(
  <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
    <HashRouter>
      <App />
    </HashRouter>
    </PersistGate>
  </Provider>
);
reportWebVitals();
