import * as React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { green, red } from '@mui/material/colors';
import { Box, Container } from '@mui/system';
import { IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useParams } from "react-router";
import { userRequest } from '../requestMethods';
import { setLoading, setNotification } from '../store/userSlice';
import { useDispatch } from 'react-redux';
import { editChecker } from '../store/apiCalls';
import { useNavigate } from "react-router-dom";
import Date from '../components/Date';
import { darkTheme } from '../themes/Dark';
import HistoryIcon from '@mui/icons-material/History';

export default function TasksCheckerEdit() {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "60%",
        bgcolor: '#2e2e2e',
        border: '2px solid #000',
        boxShadow: 24,
        color: "white",
        p: 4,

    };

    let { id } = useParams();
    const [task, setTask] = React.useState([])
    const [status, setStatus] = React.useState([])


    const [status2, setStatus2] = React.useState([])
    const [open, setOpen] = React.useState(false);

    const [details, setDetails] = React.useState({id:null,M:"",D:"",R:""});
    const handleClose = () => {
        setOpen(false);
        setDetails({id:null,M:"",D:"",R:""})
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function saveDet() { 
        let s = [...status2];
        s[details.id] = details;
        setStatus2(s);

        setDetails({id:null,M:"",D:"",R:""})
        setOpen(false)
    }
    function showDet(index) {
        setDetails(task.tasks[index].details);
        setOpen(true)
    }
    function setParams(e){
        let s = {...details,[e.target.name]:e.target.value};
        setDetails(s)
         
    }

    function setStat(i, stat) {
        let s = [...status];
        s[i] = stat;
        setStatus(s);
    }
    function setStateA(i) {
        let s = [...status];
        s[i] = !s[i];
        setStatus(s);

        if (s[i] === false) {
            setDetails({id:i});
            setOpen(true)
        }
    }
    function stat(i) {
        if (status[i] === true) {
            return "OK"
        } else if (status[i] === false) {
            return "NOK"
        } else {
            return "--"
        }
    }
    function colors(i) {
        if (status[i] === true) {
            return "success"
        } else if (status[i] === false) {
            return "error"
        } else {
            return "info"
        }
    }
    function save() {
        if (status.includes(undefined)) {
            dispatch(setNotification({
                msg: "Please compleat the check list",
                error: true
            }));
        } else {
            let d = {
                tasks: status.map((s, i) => {
                    return ({
                        task: task.tasks[i].task._id,
                        status: s,
                        details:status2[i]
                    })
                })
                // tasks:[
                //     {task : "633d65b02b69e8903e630041",status:true},
                //     {task : "633d667dd05387985981548f",status:false}
                //     ]
            }
            console.log(d);
            editChecker(dispatch, d, navigate, id);
        }

    }
    const getData = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await userRequest.get(`/machineTasksChecker/${id}`);
            let a = data.tasks.map(t => t.status);
            let a2 = data.tasks.map(t => t.details);

            setStatus(a);
            setStatus2(a2);
            setTask(data);
            dispatch(setLoading(false));

        } catch (e) {
            dispatch(setLoading(false));
        }

    };
    React.useEffect(() => {
        getData();
    }, [id]);

    return (

        (
            task.tasks &&
            <Container component="main" maxWidth="lg">
                 <ThemeProvider theme={darkTheme}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Details
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <div className='ccc1'>

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="M"
                                        label="Mesure Correctives"
                                        type="text"
                                        onChange={(e) => setParams(e)}
                                        value={details.M}

                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="R"
                                        label="Responsable"
                                        type="text"
                                        onChange={(e) => setParams(e)}
                                        value={details.R}

                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="D"
                                        label="Delais"
                                        type="text"
                                        onChange={(e) => setParams(e)}
                                        value={details.D}

                                    />
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    className="blue"
                                    onClick={saveDet}
                                >
                                    Save
                                </Button>
                            </Typography>
                        </Box>
                    </Modal>
                </ThemeProvider>
                <Box
                    sx={{
                        marginTop: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <h1 className='title' >StartUp CheckList : {task.machine.name}   
                    <Button onClick={(e) => navigate(`/history/${task.machine._id}`)}  variant="outlined" sx={{ mt: 3, mb: 2 }} endIcon={<HistoryIcon />}>
                    History
                </Button></h1>
                    <div className='infop'>
                        <div>
                            Created By : <span>{task.user.username}</span>
                        </div>
                        <div>
                            Created At : <span><Date d={task.createdAt} /> </span>
                        </div>
                    </div>
                    

                        <TableContainer component={Paper}>
                            <Table className='tcheck' sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='th'>N`=</TableCell>
                                        <TableCell className='th' align="right">Daily Tasks</TableCell>
                                        <TableCell align="right" className='th2'>Status</TableCell>
                                        <TableCell align="right" className='th2'>Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        task.tasks.map((m, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    Task{i + 1}
                                                </TableCell>
                                                <TableCell align="right" style={{ width: "80%" }}>{m.task.name}</TableCell>

                                                <TableCell align="right" className='cell'><Button className={stat(i)} onClick={(e) => setStateA(i)} variant="contained">{stat(i)}</Button></TableCell>
                                                
                                               {
                                                m.details &&(
                                                    <TableCell align="right" className=''><Button className="more" onClick={(e) => showDet(i)} variant="contained">More...</Button></TableCell>

                                                )
                                               }

                                            </TableRow>

                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                            {/* <Alert
                            //     severity={colors(i)}
                            //     className='flex'
                            //     variant="filled"
                            //     key={i}
                            //     sx={{ width: '85%', fontSize: 27, marginBottom: 1 }}
                            //     // variant="filled"
                            //     action={
                            //         <>
                            //         <IconButton onClick={e => setStat(i, false)} color="error" variant="contained" aria-label="add an alarm">
                            //             <HighlightOffIcon fontSize="large" sx={{ color: red[200], fontSize: 40 }} />
                            //         </IconButton>
                            //         <IconButton onClick={e => setStat(i, true)} color="success" variant="filled" aria-label="add an alarm">
                            //             <CheckCircleIcon sx={{ color: green[200], fontSize: 40 }} />
                            //         </IconButton>
                            //         </>
                            //     }
                            // >
                            //     {m.task.name}
                            // </Alert> */}
                    

                <Button onClick={e => save()} className="blue" variant="contained" sx={{ mt: 3, mb: 2 }} endIcon={<SendIcon />}>
                    Send
                </Button>
            </Box></Container >

        )


    );
}
