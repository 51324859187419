import React, { useEffect, useRef } from 'react'
import { Bar, Doughnut } from 'react-chartjs-2';
import Show from './Show'
import Show2 from './Show2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend,LinearScale,CategoryScale ,BarElement } from 'chart.js/auto';

ChartJS.register(ArcElement, Tooltip, Legend,LinearScale,CategoryScale,BarElement);

ChartJS.defaults.color = "black";
const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                font: {
                    size: 20,
                    color:"black"
                }
            }
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            min:0,
            max:100,
            ticks: {
                stepSize:50,
                font: {
                    size: 20,
                    family:'vazir',
                    color:"black"
                }
            }
        } ,
        x: {
            beginAtZero: true,
            ticks: {
                font: {
                    size: 20,
                    family:'vazir',
                    color:"black"
                }
            }
        },

          }}

function LigneCom({ data,types }) {

    const ch = useRef()

    useEffect(() => {


    }, [data])
    
    console.log(data);

    const [data1, setData1] = React.useState({
        labels: ['N.OK', 'OK'],
        datasets: [
            {
                label: '',
                data: [60, 40],
                backgroundColor: [
                    '#CC3636',
                    '#367E18',
                ],
            },
        ],
    })
    const [data2, setData2] = React.useState({
        labels: [" "],
        datasets: [
          
        ]
    })
    const [dada, setDada] = React.useState({
        labels: [" "," "],
        datasets: [
        ]
    })

    const colors = ["#ffb55a", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"]
    ;
    function dddd(){
        let a = data.filter(d => !d.tasks ||! d.inPost).length ;
        let b = data.length ;
        let dd ={...data1}
        // console.log(dd);
        dd.datasets[0].data = [((a/b) * 100),(100 - (a/b) * 100)]
        dd.datasets[0].minBarLength =  7
        // setDada(dd)

        return dd
    }
    function dddd2(){
        Array.prototype.groupBy = function (key) {
            return this
                .reduce((hash, obj) => {
                    if (obj[key] === undefined) return hash;
                    return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
                }, {})
        };
        console.log(data);
        let a = data.groupBy("ref") ;

        // let a = data.filter(tdf => tdf.inPost)
        // a = a.groupBy("ref") ;
        
        console.log(data);
        let labels = []
        let values = []
        Object.keys(a).forEach(k => {
            labels.push(k)
            let aa = a[k].filter(d => !d.tasks ||! d.inPost).length ;
            let b = a[k].length ;
            values.push((100 - (aa/b) * 100))

        })
        console.log(a);
        // console.log(values);
        let dd2 ={...data2}
        // dd2.labels = labels.map(l => types.find(t => t._id === l).name)
       let la = labels.map(l => types.find(t => t._id === l).name)
        data2.labels = dd2.labels

        la.forEach((l,i) => {
            dd2.datasets[i] = 
            {
                label:[la[i]] ,
                data: [values[i]],
                backgroundColor: colors[i],
                minBarLength: 7, 
            }
        })

        // dd2.datasets[1].data = [values[1]]
        // dd2.datasets[1].label = [la[1]]
        console.log(dd2);
        return dd2
    }

    return data && (
        <div className='ligne-com'>
          
            <div className="contt" >
            <h1 style={{ color: "black" }} className="tit">
                {data[0].ligne}
                <Show data={data.find(t => t.position == "0")}/>
            </h1>
                <div className='contt2'>
                    <div className="chart-container" style={{ position: "relative",  width: "50%" }}>
                        <Doughnut options={options} ref={ch} data={dddd()} redraw={true}  className="barr"/>
                    </div>
                    <div className="chart-container" style={{ position: "relative",  width: "80%" }}>
                        <Bar options={options} data={dddd2()}  className="barr" />
                    </div>
                </div>
                <div className="ligne">
                    <div className="h  grid-d">
                        <img className="imgL" src='/ligne003.png' />
                        <Show2 data={data.filter(t => t.position == "10")} />
                    </div>
                    <div className="h1 grid-d">
                        <img className="imgL" src='/ligne002.png' />
                        <Show2 data={data.filter(t => t.position == "20")} />
                    </div>
                    <div className="d1 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "1")} />
                    </div>
                    <div className="d2 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "2")} />
                    </div>
                    <div className="d3 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(d => d.position == "3")} />
                    </div>
                    <div className="d4 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "4")} />
                    </div>
                    <div className="d5 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "5")} />
                    </div>
                    <div className="d6 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "6")} />
                    </div>
                    <div className="d7 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "7")} />
                    </div>
                    <div className="d8 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "8")} />
                    </div>
                    <div className="d9 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "9")} />
                    </div>
                    <div className="d10 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "19")} />
                    </div>
                    <div className="d11 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "18")} />
                    </div>
                    <div className="d12 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "17")} />
                    </div>
                    <div className="d13 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "16")} />
                    </div>
                    <div className="d14 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "15")} />
                    </div>
                    <div className="d15 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "14")} />
                    </div>
                    <div className="d16 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "13")} />
                    </div>
                    <div className="d17 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "12")} />
                    </div>
                    <div className="d18 grid-d">
                        <img className="imgL" src='/ligne001.png' />
                        <Show2 data={data.filter(t => t.position == "11")} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LigneCom