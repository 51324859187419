import React from 'react'
import { useNavigate } from 'react-router';
import { setLoading } from '../store/userSlice';
import { useDispatch } from 'react-redux';
import { userRequest } from '../requestMethods';
import QRCode from "react-qr-code";

function DataDown() {
    const [machine, setMachine] = React.useState(null)
    const [search, setsearch] = React.useState("")

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const getData = async (uri) => {
        dispatch(setLoading(true));
        try{
           
            const { data } = await userRequest.get(uri);
            setMachine(data.machines);
            dispatch(setLoading(false));

        }catch(e){
            dispatch(setLoading(false));
        }
    };
    React.useEffect(() => {
        if(search.length >= 3){
            getData(`/machine/search/${search}`);
        }else if(search.length === 0){
            getData(`/machine/qrs`);
        }
    }, [search]);

    function arrayToCsv(data){
        return data.map(row =>
          row
          .map(String)  // convert every value to String
          .map(v => v.replaceAll('"', '""'))  // escape double colons
          .map(v => `"${v}"`)  // quote it
          .join(',')  // comma-separated
        ).join('\r\n');  // rows starting on new lines
      }

      /** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
function downloadBlob(content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }
    function down(){

       let ma = machine.map(m => ([m.name,m.ligne.name,m.position,m.ref.name,m._id]));
       let csv = arrayToCsv(ma);
        downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;')
    }
  return machine && (
    <div className='qqq'>
        {/* <button onClick={down} >down</button> */}
        {machine.map(m => (
            <div>
                <span>{m.name}</span>
                <span>{m.ligne.name}</span>
                <QRCode value={m._id} />
            </div>
        ))}
    </div>
  )
}

export default DataDown