import { createTheme } from "@mui/material";

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#0092a8',
      },
    },
  });

  export { darkTheme };