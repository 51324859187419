import React from 'react'
import { useNavigate } from 'react-router';

function Show({ data }) {
  const navigate = useNavigate();
    // console.log(data);
    function nav(mm) {
      if(mm.inPost)
      navigate(`/taskschecker/edit/${mm.id}`)
    }
  return (

    data && (
        <span onClick={e => nav(data)} style={{backgroundColor:!data.inPost ? "#F9690E	" : data.tasks?"#367E18":"#CC3636"}}></span>
    )

  )
}

export default Show