import { Button } from '@mui/material';
import { useLocation, useNavigate} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();
  

  return (location.pathname != "/Dashboard" &&
    <div className='subNav' >
        <Button variant="text" onClick={() => navigate(-1)}> <ArrowBackIcon/> Go back</Button>
            </div>
  );
}
