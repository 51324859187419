import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import {  userRequest } from '../requestMethods'

const initialState = {
  currentUser:{

    },
    notification:{
      msg:"",
      error:false
    }
    ,loading:false,
    qrCode:""

}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginFailure: (state, action) => {
      state.loading = false
      state.notification = {
        msg:"Loging Failed ",
        error:true
      }
    },
    loginStart: (state, action) => {
      state.loading = true
    },
    loginOut: (state, action) => {
      state.currentUser = {}
      state.notification = {
        msg:"Logged Out ",
        error:false
      }
      userRequest.defaults.headers.common['token'] = ``;

    },
    loginSuccess: (state, action) => {
      state.loading = false
      state.notification = {
        msg:"Welcome Agin ",
        error:false
      }
      state.currentUser = action.payload
      axios.defaults.headers.common['token'] = `Bearer ${state.currentUser.accessToken}`;

    },
    setNotification: (state, action) => {
      state.loading = false
      state.notification = action.payload
      
    },
    setLoading: (state, action) => {
      state.loading = action.payload      
    },
    setQrCode: (state, action) => {
      state.qrCode = action.payload      
    },

  },
})

export const { loginFailure, loginStart,setQrCode,setLoading, loginSuccess,setNotification,loginOut } = userSlice.actions

export default userSlice.reducer