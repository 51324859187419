import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { editUser } from '../store/apiCalls';
import { useDispatch } from "react-redux";
import { Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { userRequest } from '../requestMethods';
import { setLoading } from '../store/userSlice';

export default function EditUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = React.useState({})

  let { id } = useParams();

  const getData = async (uri) => {
    dispatch(setLoading(true));
    try{
        const { data } = await userRequest.get(uri);
        setUser(data);
        dispatch(setLoading(false));

    }catch(e){
        dispatch(setLoading(false));
    }

};
React.useEffect(() => {
    getData(`/user/find/${id}`);
}, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
        email: data.get('email'),
        username: data.get('username'),
        post: data.get('post'),
        isAdmin: data.get('admin')=== "on"?true:false,
        password: data.get('password'),
      });
      editUser(dispatch,{
      email: data.get('email'),
      username: data.get('username'),
      post: data.get('post'),
      isAdmin: data.get('admin')=== "on"?true:false,
      password: data.get('password'),
    },navigate,id)
  };
  

  return (
    user.email &&
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <h1 className='title' >Edit User</h1>
          <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete= "no"
              inputProps={{
                autoComplete: ''
             }}
             defaultValue={user.username}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete= "no"

              inputProps={{
                autoComplete: ''
             }}
             defaultValue={user.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="post"
              label="Post"
              name="post"
              defaultValue={user.post}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
            />
            <FormControlLabel control={<Checkbox name='admin' defaultChecked={user.isAdmin} />} label="Admin" style={{color:"white"}} />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className="blue"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
  );
}
